<template>
  <div class="h-100 w-100">
    <page-header>
      <span class="mr-2">{{ postMetricStr }}</span>
    </page-header>
    <div class="fr" style="height: calc(100% - 50px)">
      <tableu ref="tableuRef" :api="api.post"  edge="post">
        <template #thead>
          <tr>
            <th>Social</th>
            <th>Link</th>
            <th>Content</th>
            <th>Ngày tạo</th>
            <th>Trạng thái</th>
            <th>Ngày đăng</th>
            <th>Views</th>
            <th>Account</th>
            <th>Nguồn</th>
            <th>Hành động</th>
          </tr>
        </template>
        <template #body="{ items, deleteItem }">
          <tr v-for="item of items" :key="item._id">
            <td>{{ item.social }}</td>
            <td>{{ item.link }}</td>
            <td>
              <p style="max-width: 300px; line-break: initial">{{ shortenContent(item.content) }}</p>
            </td>
            <td>{{ item.createdDate && dayjs(item.createdDate).format('HH:mm DD/MM/YY') }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.postedDate && dayjs(item.postedDate).format('HH:mm DD/MM') }}</td>
            <td>{{ item.views }}</td>
            <td>{{ item.account?.account?.split("|")[0] }}</td>
            <td>{{ item.source && item.source.profile }}</td>
            <td>
              <div class="fr ai-c fg-1">
                <tooltip content="Xoá">
                  <button class="fn-btn" @click="deleteItem([item._id])">
                    <icon>fas fa-times@16:#d50404</icon>
                  </button>
                </tooltip>
              </div>
            </td>
          </tr>
        </template>
        <template #footer>
          <select v-model="selected_folder">
          <option v-for="item of folderList" :value="item._id">
            {{item._id}}
          </option>
        </select>
        </template>
      </tableu>
    </div>
  </div>
</template>

<script setup>
import PageHeader from '@/components/PageHeader';
import api from '@/api'
import Tableu from '@/components/UiLib/Tableu';
import dayjs from 'dayjs';
import Icon from '@/components/UiLib/Icon';
import { metric2Str} from '@/utils';
import notification from '@/components/UiLib/Api/notification';
import _ from 'lodash';
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import Tooltip from '@/components/UiLib/Tooltip';

const tableuRef = ref()

function shortenContent(title) {
  return title && title.substr(0, 60) + '...'
}

let loadPostMetricInterval
const postMetric = ref({})
const postMetricStr = computed(() => metric2Str(postMetric.value))
const getPostMetric = () => api.post.metric(selected_folder.value?{"account.folder":selected_folder.value}:undefined).then(data => postMetric.value = data)

const folderList = ref({})
const selected_folder = ref()

function loadFolderList() {
  api.account.folders().then(rs => {folderList.value = rs && rs.length ? [{_id: ""}].concat(rs):[]}).catch(e => notification.err('Failed to load script with error: ' + e.message))
}
watch(() => selected_folder.value, () => {
  // if (selected_folder.value) {
    tableuRef.value.loadItems(selected_folder.value?{"account.folder":selected_folder.value}:undefined)
    getPostMetric()
  // }
})

onMounted(() => {
  getPostMetric()
  loadFolderList()
  loadPostMetricInterval = setInterval(getPostMetric, 30000)
})
onBeforeUnmount(() => clearInterval(loadPostMetricInterval))
</script>
