import { API_URL } from '@/constants';
import axios from 'axios';
import notification from '@/components/UiLib/Api/notification';

const $ = async fn => {
  try {
    const { data } = await fn()
    return data
  } catch (e) {
    if (e.constructor && e.constructor.name === 'AxiosError') {
      notification.err(e.response.data)
    } else {
      notification.err(e)
    }
  }
}

class API {
  constructor(path) {
    this.path = path
  }

  // crud
  async create(items) {
    return $(() => axios.post(`${API_URL}/${this.path}`, { payload: items }))
  }

  async read(id) {
    return $(() => axios.get(`${API_URL}/${this.path}/${id}`))
  }

  async update(id, change) {
    return $(() => axios.put(`${API_URL}/${this.path}/${id}`, { payload: change }))
  }

  async delete(id) {
    return $(() => axios.delete(`${API_URL}/${this.path}/${id}`))
  }

  // extra common
  async readAll(page, noPaging = false, condition, sort) {
    let strConditionQuery = ""
    if (condition && Object.keys(condition).length > 0)
      strConditionQuery = "&strConditionQuery=" + Object.keys(condition).map(c => { return `${c}%3D${condition[c]}` }).join("%2C")
    
    let strSortQuery = ""
    if (sort && Object.keys(sort).length > 0)
      strSortQuery = "&strSortQuery=" + Object.keys(sort).map(s => { return `${s}%3D${sort[s]}` }).join("%2C")
    
    return $(() => axios.get(`${API_URL}/${this.path}s?page=${page}&noPaging=${noPaging}${strConditionQuery}${strSortQuery}`))
  }

  async total() {
    return $(() => axios.get(`${API_URL}/${this.path}s/total`))
  }

  async metric(condition) {
    let strConditionQuery = ""
    if (condition && Object.keys(condition).length > 0)
      strConditionQuery = "?strConditionQuery=" + Object.keys(condition).map(c => { return `${c}%3D${condition[c]}` }).join("%2C")
    return $(() => axios.get(`${API_URL}/${this.path}s/metric${strConditionQuery}`))
  }

  async deleteBroken() {
    return $(() => axios.post(`${API_URL}/${this.path}s/delete-broken`))
  }

  async deleteByIds(payload) {
    return $(() => axios.post(`${API_URL}/${this.path}s/delete-by-ids`, { payload }))
  }
}

class AccountAPI extends API {
  constructor(path) {
    super(path);
  }

  async deleteByAccounts(payload) {
    return $(() => axios.post(`${API_URL}/accounts/delete-by-accounts`, { payload }))
  }

  async deleteNonePreAccounts() {
    return $(() => axios.post(`${API_URL}/accounts/delete-none-pre-accounts`, {}))
  }

  async release(_id) {
    return this.update(_id, {
      status: 'NEW',
      loginFailedReason: '',
      usedBy: '',
      premium: 'UNDETERMINED',
      premiumEndDate: undefined,
      lockUntil: undefined
    })
  }

  async analysisTotal() {
    return $(() => axios.post(`${API_URL}/accounts/analysis-total`))
  }

  async analysis(page) {
    return $(() => axios.post(`${API_URL}/accounts/analysis?page=${page}`))
  }

  async premiumStatus() {
    return $(() => axios.get(`${API_URL}/${this.path}s/premium-status`))
  }

  async folders() {
    return $(() => axios.get(`${API_URL}/${this.path}s/folders`))
  }
}

class ProxyAPI extends API {
  constructor(path) {
    super(path);
  }

  async getBrokenProxies() {
    return $(() => axios.get(`${API_URL}/proxys/broken`))
  }

  async deleteBrokenByIp(ip) {
    return $(() => axios.post(`${API_URL}/proxys/delete-broken-by-ip/${ip}`, {}))
  }

  async deleteByIp(ip) {
    return $(() => axios.post(`${API_URL}/proxys/delete-by-ip/${ip}`, {}))
  }

  async release(_id) {
    this.update(_id, { status: 'NEW', usedBy: '' })
  }

  async usage() {
    return $(() => axios.get(`${API_URL}/proxys/usage`))
  }
}

class VideoAPI extends API {
  constructor(path) {
    super(path);
  }

  async deleteByUrls(payload) {
    return $(() => axios.post(`${API_URL}/videos/delete-by-urls`, { payload }))
  }

  async setDuration(duration) {
    return $(() => axios.post(`${API_URL}/videos/set-duration`, { duration }))
  }
}

class VpsAPI extends API {
  constructor(path) {
    super(path);
  }

  async totalRunningTabs() {
    return $(() => axios.get(`${API_URL}/vpss/total-running-tabs`))
  }

  async totalAllocTabs() {
    return $(() => axios.get(`${API_URL}/vpss/total-alloc-tabs`))
  }

  async totalConfigTabs() {
    return $(() => axios.get(`${API_URL}/vpss/total-config-tabs`))
  }

  async release(_id) {
    return $(() => axios.post(`${API_URL}/vpss/release/${_id}`, {}))
  }

  async clearFullDisk(_id) {
    return $(() => axios.post(`${API_URL}/vpss/clear-full-disk/${_id}`, {}))
  }

  async find(ip) {
    return $(() => axios.get(`${API_URL}/vps/find/${ip}`, {}))
  }
}

class ScriptAPI extends API {
  constructor(props) {
    super(props);
  }

  async publish(id) {
    return $(() => axios.post(`${API_URL}/${this.path}/publish/${id}`))
  }

  async getLatestCode() {
    return $(() => axios.get(`${API_URL}/${this.path}/publish/latest_code`))
  }

  async getLatestVersion() {
    return $(() => axios.get(`${API_URL}/${this.path}/publish/latest_version`))
  }

  async readErrors(id, page) {
    return $(() => axios.get(`${API_URL}/${this.path}/read-errors/${id}?page=${page}`))
  }

  async clearErrors(id) {
    return $(() => axios.get(`${API_URL}/${this.path}/clear-errors/${id}`))
  }

  async countErrors(id, workerId = '') {
    let url = `${API_URL}/${this.path}/count-errors/${id}`
    if (workerId)
      url += `?workerId=${workerId}`
    return $(() => axios.get(url))
  }

  async createErrorFilter(error) {
    return $(() => axios.post(`${API_URL}/${this.path}-error-filter`, { payload: { error, enabled: true } }))
  }

  async getErrorFilters() {
    return $(() => axios.get(`${API_URL}/${this.path}-error-filters`))
  }

  async updateErrorFilter(_id, change) {
    $(() => axios.put(`${API_URL}/${this.path}-error-filter/${_id}`, { payload: change })).then(() => notification.success('Saved'))
  }

  async applyErrorFilters() {
    $(() => axios.post(`${API_URL}/${this.path}-error-filters/apply`)).then(() => notification.success('Applied'))
  }
}

class TabEventAPI extends API {
  constructor(props) {
    super(props);
  }

  async read(acc, workerId) {
    return $(() => axios.get(`${API_URL}/${this.path}s?account=${acc}&workerId=${workerId}`))
  }
}

class SystemConfigAPI extends API {
  constructor(props) {
    super(props);
  }

  async gets() {
    return $(() => axios.get(`${API_URL}/${this.path}`))
  }

  async get(key) {
    return $(() => axios.get(`${API_URL}/${this.path}/${key}`))
  }

  async set(key, value) {
    $(() => axios.post(`${API_URL}/${this.path}/${key}`, { payload: value })).then(() => notification.success('Saved'))
  }

  async unset(key) {
    return $(() => axios.delete(`${API_URL}/${this.path}/${key}`))
  }
}

const apiCache = {}

export default new Proxy({}, {
  get(target, prop) {
    if (!apiCache[prop]) {
      switch (prop) {
        case 'account':
          apiCache[prop] = new AccountAPI(prop)
          break;
        case 'proxy':
          apiCache[prop] = new ProxyAPI(prop)
          break;
        case 'video':
          apiCache[prop] = new VideoAPI(prop)
          break;
        case 'vps':
          apiCache[prop] = new VpsAPI(prop)
          break;
        case 'script':
          apiCache[prop] = new ScriptAPI(prop)
          break;
        case 'tabEvent':
          apiCache[prop] = new TabEventAPI(prop)
          break;
        case 'systemConfig':
          apiCache[prop] = new SystemConfigAPI(prop)
          break;
        default:
          apiCache[prop] = new API(prop)
      }
    }
    return apiCache[prop]
  }
})
